const fr = {
  'Home': 'Accueil',
  'About': 'Sur',
  'Services': 'Prestations de service',
  'Skills': 'Compétences',
  'Projects': 'Projets',
  'Contact': 'Contact',
  'Portfolio Header': 'En-tête de portefeuille',
  'No': 'Non',
  'Copyright issues': 'Problème de copyright',
  'Made with': 'Fabriqué avec',
  'in India!': 'en Inde!',

  'who i am': "qui suis-je",
  'what i provide': "Ce que je fournit",
  'what i know': "ce que je sais",
  "what i've worked on": "Ce que j'ai travaillé sur",
  'get in touch': "entrer en contact",

  'Hello, My name is': 'Bonjour, mon nom est',
  "I'm a": 'Je suis un',
  'Hire Me': 'Engagez moi',

  'About me': 'À propos de moi',
  "I'm": 'Je suis',
  "and I'm a ": 'et Je suis un ',
  'Download Resume': 'Télécharger CV',
  ABOUT_ME_PART_1: "J'ai travaillé avec",
  ABOUT_ME_PART_2: "pendant plus de 3 ans avec une expérience peu mais significative dans",
  ABOUT_ME_PART_3: "Je suis un associé multi-talentueux qui est toujours récompensé pour réussir dans la planification et les améliorations opérationnelles. J'ai une excellente réputation pour résoudre les problèmes et améliorer la satisfaction de la clientèle. Je suis motivé pour apprendre et grandir, et enthousiaste et désireux de contribuer au succès de l'équipe grâce au travail acharné, avec une attention particulière aux détails et d'excellentes compétences organisationnelles.",

  'My Services': 'Mes services',
  'Web Design & Development': 'Web Design & Development',
  'Quality Assurance': 'Assurance qualité',
  'Optimization & Evolution': 'Optimisation et évolution',
  'Skilled in taming a wide variety of web development tools including responsive web designs.': "Spécialisé pour apprivoiser une grande variété d'outils de développement Web, y compris des conceptions Web réactives.",
  'Sophisticated quality management approach to confirm secure, sustainable and faster web apps.': 'Approche sophistiquée de gestion de la qualité pour confirmer des applications Web sécurisées, durables et plus rapides.',
  'Continuously optimize and enhance web apps for higher perfomance and clean code.': 'Optimiser et améliorer continuellement les applications Web pour une performance supérieure et un code de nettoyage.',

  'My Skills': 'Mes compétences',
  'Technologies': 'Les technologies',
  'Tools': 'Outils',

  'My Projects': 'Mes projets',
  "India's #1 bus transport technology company app for bus operators and agencies.": "L'application de la société de technologie de transport de bus n ° 1 de l'Inde pour les opérateurs de bus et les agences.",
  'Digital ecosystem to accelerate and enable co-commerce.': 'Écosystème numérique pour accélérer et permettre la coopération.',
  'Emergency Medical Services and Ambulance Software in India.': "Services médicaux d'urgence et logiciels d'ambulance en Inde.",
  'Fire inspection software to generate inspection reports, work orders and quotes.': "Logiciel d'inspection des incendies pour générer des rapports d'inspection, des ordonnances de travail et des citations.",
  "Carolina Herrera's official shopping website for luxury fashion and beauty products.": 'Le site Web officiel de CAROLINA HERRERA pour les produits de mode de luxe et de beauté.',
  'Maintain properties with automated lettings to save time, hassle & money.': "Maintenir les propriétés avec des locations automatisées pour gagner du temps, des tracas et de l'argent.",

  "Team": "Équipe",
  "who with me": "qui avec moi",
  "My Team": "Mon équipe",
  UTKARSH_REC: "Shivani sait comment gérer plusieurs tâches et histoires d'utilisateurs parallèles. Elle a de bonnes compétences en gestion et en leadership.",
  ADITI_REC: "Shivani a non seulement travaillé avec enthousiasme chaque jour, mais également la positivité de l'équipe. Elle est multi-tâches et vraiment favorable.",
  MOHIT_REC: "Shivani est l'un des meilleurs développeurs frontaux de l'organisation. Je crois qu'elle sera un excellent atout à une équipe qu'elle rejointe à l'avenir.",
  JANAK_REC: "Shivani est extrêmement capable et va régulièrement au-delà de son rôle pour faire le travail. Son approche positive fait d'elle un ajout bienvenu à toute équipe.",

  'Contact Me': 'Contactez moi',
  'Get in Touch': 'Entrer en contact',
  'Please feel free to reach me out.': "S'il vous plaît n'hésitez pas à me joindre.",
  "If you don't get a reply immediately, I might just be coding out a new screen.": "Si vous n'obtenez pas de réponse immédiatement, je pourrais simplement coder un nouvel écran.",
  "I'll get back to you as soon as I can. That's a promise!": "Je reviendrai vers vous dès que je peux. C'est une promesse!",
  'Name': 'Nom',
  'Email': 'E-mail',
  'LinkedIn': 'LinkedIn',
  'Subject': 'Sujet',
  'Message': 'Message',
  'Message me': 'Envoie moi un message',
  'Send Message': 'ENVOYER LE MESSAGE',
};

export default fr;
