const projects = [
    {
        name: "Chalo (Zophop)",
        link: "https://chalo.com/",
        description: "India's #1 bus transport technology company app for bus operators and agencies.",
        logo: "images/chalo-logo.png",
    },
    {
        name: "Asort",
        link: "https://asort.com/",
        description: "Digital ecosystem to accelerate and enable co-commerce.",
        logo: "images/asort-logo.png",
    },
    {
        name: "Ziqitza-VTS",
        link: "https://zhl.org.in/",
        description: "Emergency Medical Services and Ambulance Software in India.",
        logo: "images/ziqitza-logo.png",
    },
    {
        name: "Aries Firelab",
        link: "https://www.firelabinc.com/",
        description: "Fire inspection software to generate inspection reports, work orders and quotes.",
        logo: "images/aries-logo.png",
    },
    {
        name: "Carrolina Herrera",
        link: "https://www.carolinaherrera.com/ww/en/",
        description: "Carolina Herrera's official shopping website for luxury fashion and beauty products.",
        logo: "images/ch-logo.webp",
    },
    {
        name: "Ringley (Planetrent)",
        link: "https://planetrent.co.uk/",
        description: "Maintain properties with automated lettings to save time, hassle & money.",
        logo: "images/ringley-logo.png",
    },
];

const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
    responsive: [
      {
        breakpoint: 750,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
    ],
};

export default projects;
export {
    settings,
};
