const techs = [
    {
        name: "React JS (Domain Expertise)",
        percent: 90,
        style: { fontSize: "16px" }

    },
    {
        name: "JavaScript",
        percent: 90,
    },
    {
        name: "HTML",
        percent: 90,
    },
    {
        name: "CSS, SCSS, SASS",
        percent: 90,
    },
    {
        name: "Next Js",
        percent: 60,
    },
    {
        name: "Node JS",
        percent: 45,
    },
    {
        name: "MongoDB | MySQL",
        percent: 30,
    },
    
    {
        name: "Java",
        percent: 30,
    },
];

const tools = [
    {
        name: "Visual Studio Code",
        percent: 95,
    },
    {
        name: "Git",
        percent: 90,
    },
    {
        name: "Bitbucket",
        percent: 90,
    },
    {
        name: "Jenkins",
        percent: 50,
    },
    {
        name: "Jira",
        percent: 75,
    },
    {
        name: "Trello",
        percent: 90,
    },
    {
        name: "Robo3t",
        percent: 70,
    },
    {
        name: "IntelliJ",
        percent: 50,
    },
];

const os = [
    {
        name: "Linux",
        percent: 90,
    },
    {
        name: "Mac",
        percent: 80,
    },
    {
        name: "Windows",
        percent: 90,
    },
];

export {
    techs,
    tools,
    os
};
