import LocalizedStrings from 'react-localization';
import en from './en';
import fr from './fr';

const localisedStrings = new LocalizedStrings({
  en,
  fr,
});

export default localisedStrings;
