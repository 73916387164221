const en = {
  Home: 'Home',
  About: 'About',
  Services: 'Services',
  Skills: 'Skills',
  Projects: 'Projects',
  Team: 'Team',
  Contact: 'Contact',
  'Portfolio Header': 'Portfolio Header',
  'No': 'No',
  'Copyright issues': 'Copyright issues',
  'Made with': 'Made with',
  'in India!': 'in India!',

  'who i am': 'WHO I AM',
  'what i provide': 'WHAT I PROVIDE',
  'what i know': 'WHAT I KNOW',
  "what i've worked on": "WHAT I'VE WORKED ON",
  'who with me': 'WHO WITH ME',
  'get in touch': 'GET IN TOUCH',

  'Hello, My name is': 'Hello, My name is',
  "I'm a": "I'm a",
  'Hire Me': 'Hire Me',

  'About me': 'About me',
  "I'm": "I'm",
  "and I'm a ": "and I'm a ",
  'Download Resume': 'Download Resume',
  ABOUT_ME_PART_1: "I have worked with",
  ABOUT_ME_PART_2: "for over 4 years with a little but significant experience in",
  ABOUT_ME_PART_3: "I am a multi-talented Associate who is consistently rewarded for success in planning and operational improvements. I have an excellent reputation for resolving problems, and improving customer satisfaction. I am motivated to learn and grow, and enthusiastic and eager to contribute to team success through hard work, with attention to detail, and excellent organizational skills.",

  'My Services': 'My Services',
  'Web Design & Development': 'Web Design & Development',
  'Quality Assurance': 'Quality Assurance',
  'Optimization & Evolution': 'Optimization & Evolution',
  'Skilled in taming a wide variety of web development tools including responsive web designs.': 'Skilled in taming a wide variety of web development tools including responsive web designs.',
  'Sophisticated quality management approach to confirm secure, sustainable and faster web apps.': 'Sophisticated quality management approach to confirm secure, sustainable and faster web apps.',
  'Continuously optimize and enhance web apps for higher perfomance and clean code.': 'Continuously optimize and enhance web apps for higher perfomance and clean code.',

  'My Skills': 'My Skills',
  Technologies: 'Technologies',
  Tools: 'Tools',

  'My Projects': 'My Projects',
  "India's #1 bus transport technology company app for bus operators and agencies.": "India's #1 bus transport technology company app for bus operators and agencies.",
  "Digital ecosystem to accelerate and enable co-commerce.": "Digital ecosystem to accelerate and enable co-commerce.",
  "Emergency Medical Services and Ambulance Software in India.": "Emergency Medical Services and Ambulance Software in India.",
  "Fire inspection software to generate inspection reports, work orders and quotes.": "Fire inspection software to generate inspection reports, work orders and quotes.",
  "Carolina Herrera's official shopping website for luxury fashion and beauty products.": "Carolina Herrera's official shopping website for luxury fashion and beauty products.",
  "Maintain properties with automated lettings to save time, hassle & money.": "Maintain properties with automated lettings to save time, hassle & money.",

  'My Team': 'My Team',
  UTKARSH_REC: "Shivani knows how to handle multiple tasks and user stories parallely. She has good managing and leadership skills.",
  ADITI_REC: "Shivani not only worked enthusiastically each day but also spread positivity in the team. She's multi tasking and really supportive.",
  MOHIT_REC: "Shivani is one of best frontend developer in organization. I believe she'll be great asset to any team she'll join in future.",
  JANAK_REC: "Shivani is extremely capable & regularly goes beyond her role to get job done. Her positive approach make her welcome addition to any team.",

  'Contact Me': 'Contact Me',
  'Get in Touch': 'Get in Touch',
  "Please feel free to reach me out.": "Please feel free to reach me out.",
  "If you don't get a reply immediately, I might just be coding out a new screen.": "If you don't get a reply immediately, I might just be coding out a new screen.",
  "I'll get back to you as soon as I can. That's a promise!": "I'll get back to you as soon as I can. That's a promise!",
  Name: 'Name',
  Email: 'Email',
  LinkedIn: 'LinkedIn',
  Subject: 'Subject',
  Message: 'Message',
  'Message me': 'Message me',
  'Send Message': 'Send Message',
};

export default en;
